import { render, staticRenderFns } from "./review.vue?vue&type=template&id=2e33ef7d&scoped=true&"
import script from "./review.vue?vue&type=script&lang=js&"
export * from "./review.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e33ef7d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Rating: require('/var/www/www-root/data/www/o-kassa.com/components/Rating.vue').default})
